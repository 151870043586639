<template>
  <div class="flex-row flex-wrap d-flex justify-content-start">
    <template v-for="(part, idx) in formOptions">
      <slot :name="'slot' + idx"></slot>
      <template v-for="(f, idx2) in part.filter(e => !(excludeKeys && excludeKeys.length) || !excludeKeys.includes(e.key))">
        <template v-if="(!f.role || $R(f.role)) && !(isStat && f.hideInStat)">
          <div v-if="f.key === 'divider'" class="w-100" :key="idx2"></div>
          <div v-else class="flex-grow-0 mb-1 mr-2" :key="f.key">
            <div>
              <small class="mb-n2">
                <span :title="f.key">{{ f.name }}</span>
                <span v-if="f.type === 'checkbox'" class="pointer" @click="toggleAll(f.key, f.options.filter(e => !e.role || $R(e.role)))">
                  {{ ' &#128505;' }}
                </span>
              </small>
            </div>
            <b-form-checkbox-group v-if="f.type === 'checkbox'" :class="`options-btn-${f.variant || 'success'}`" class="d-block options-btn"
                                   v-model="form[f.key]" :options="f.options.filter(e => !e.role || $R(e.role))" button-variant="light" size="sm" buttons>
            </b-form-checkbox-group>
            <b-btn-group v-else class="d-block">
              <!-- b-form-radio-group 을 이용할 수도 있으나 버튼별로 색을 달리하거나 click 이벤트 커스텀을 위해 btn-group 으로 작성한다.-->
              <b-btn v-for="o in f.options.filter(e => !e.role || $R(e.role))" size="sm" :key="o.value"
                     :variant="form[f.key] === o.value ? (o.variant || (o.value === 'ALL' ? 'dark' : '')): 'light'"
                     @click="o.click ? o.click() : form[f.key] = o.value">
                {{ o.text }}
              </b-btn>
            </b-btn-group>
          </div>
        </template>
      </template>
    </template>
    <slot name="slotEnd"></slot>
  </div>
</template>

<script>
export default {
  name: 'FormOptions',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    formOptions: Array,
    excludeKeys: Array,
    isStat: Boolean,
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    toggleAll(key, options) {
      if (this.form[key].length === options.length) {
        this.form[key] = [];
      } else {
        this.form[key] = options.map(e => e.value);
      }
    }
  }
}
</script>
