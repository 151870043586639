<template>
  <div>
    <b-card no-body>
      <div slot="header">
        <strong>
          규칙 추가
        </strong>
        <div class="card-header-actions">
          <b-link class="card-header-action btn-minimize" v-b-toggle.addRule>
            <i :class="`icon-arrow-${collapse.addRule ? 'up' : 'down'}`"></i>
          </b-link>
        </div>
      </div>
      <b-collapse id="addRule" v-model="collapse.addRule">
        <b-card-body>
          <b-row>
            <b-col cols="4">
              <b-textarea placeholder="금칙어를 엔터로 구분해서 입력해주세요." rows="6" v-model="form.list.words">
              </b-textarea>
              <b-button @click="addWords()" class="mt-1 mr-1" variant="success" :disabled="busy.add">
                금칙어 추가
                <b-spinner class="mr-1" small v-if="busy.add"></b-spinner>
              </b-button>
            </b-col>
            <b-col cols="4">
              <b-textarea :placeholder="`치환규칙을 => 와 엔터로 구분해서 입력해주세요.\nex)\n기존=>바뀔단어\n22fw당일발송=>22fw`" rows="6" v-model="form.list.replaces">
              </b-textarea>
              <b-button @click="addReplaces()" class="mt-1 mr-1" variant="success" :disabled="busy.add">
                치환규칙 추가
                <b-spinner class="mr-1" small v-if="busy.add"></b-spinner>
              </b-button>
            </b-col>
            <b-col cols="4">
              <b-textarea placeholder="상품명을 테스트할 발란코드를 입력해주세요." rows="6" v-model="form.list.goods_no"></b-textarea>
              <div class="mt-1">
                <b-button @click="downTestXlsx()" class="mr-1" variant="success">결과 Xlsx 다운로드</b-button>
                <b-button @click="test()" class="mr-1" variant="outline-success">직접 입력 테스트</b-button>
              </div>
            </b-col>
          </b-row>
          <hr />
          <div class="mb-2">
            <b-button @click="modal.warn = true" class="mr-1" variant="warning">주의사항</b-button>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card>
      <b-input-group class="mb-1">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list()" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="대상단어, 치환단어" v-model="form.list.search" @keypress.enter="list()" v-focus></b-form-input>
      </b-input-group>

      <form-options v-model="form.list" v-bind="{formOptions}"></form-options>

      <div class="mt-2">
        <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
      </div>
    </b-card>

    <div class="clearfix mb-1">
      <div class="pull-left">
        <b-button @click="removeSelected()" class="" variant="danger" :disabled="busy.remove">
          선택삭제
          <b-spinner class="mr-1" small v-if="busy.remove"></b-spinner>
        </b-button>
      </div>
      <div class="pull-right">
        <b-dropdown right variant="light" class="ml-1">
          <template #button-content>
            <i class="fa fa-copy"></i>
          </template>
          <b-dropdown-item @click="copy('word')">대상단어만</b-dropdown-item>
          <b-dropdown-item @click="copy('rule')">규칙,대상단어,치환단어</b-dropdown-item>
          <b-dropdown-item @click="copy('all')">전체</b-dropdown-item>
        </b-dropdown>
        <b-button class="ml-1" variant="success" @click="downXlsx()">XLSX</b-button>
<!--        <b-dropdown right text="XLSX" variant="success" class="m-1">
          <b-dropdown-item @click="downXlsx()">전체</b-dropdown-item>
          <b-dropdown-item @click="downXlsx('remove')">금칙어만</b-dropdown-item>
          <b-dropdown-item @click="downXlsx('replace')">치환규칙만</b-dropdown-item>
        </b-dropdown>-->
      </div>
    </div>

    <c-table id="ForbiddenWord" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 데이터'" @get-more="list(true)">
    </c-table>

    <b-modal title="주의사항" size="lg" v-model="modal.warn" ok-only>
      <h5>- 금칙어는 대소문자 구분 없이 상품명에서 제거됩니다.</h5>
      - 금칙어는 여러 개 존재해도 전부 제거됩니다.<br/>
      <div class="pl-3">
        ex) 금칙어: 최저가 일 때<br/>
        [최저가] 최저가 상품입니다 => 상품입니다<br/>
      </div>
      - 금칙어는 좌우에 영문,숫자,한글 이 아닌 문자가 있을 때(흔히 기호, 공백) 해당 문자와 함께 제거됩니다.<br/>
      <div class="pl-3">
        ex) 금칙어: 국내, 당일 일 때<br/>
        [국내/당일/구찌] => 구찌<br/>
        ex) 금칙어: 세일, 스퀘어 일 때<br/>
        세일러 => 세일러 (변경없음)<br/>
        디스퀘어드2 => 디스퀘어드2 (변경없음)<br/>
      </div>
      - / 에 대해서는 양 옆에 공백이 하나 이상 있을 때 제거됩니다(ex: 22 S/S => 22 S/S, 구찌 / 가방 => 구찌 가방).<br/>
      - 위의 금칙어 룰은 띄어쓰기가 없을 때는 적용되지 않기에(ex: 당일배송22fw구찌) 이 때는 치환규칙을 이용할 수 있습니다(ex: 당일배송22fw구찌=>22fw 구찌 등의 규칙)<br/>
      - 치환규칙은 금칙어보다 먼저 적용됩니다.<br/>
      - 금칙어를 새로 등록해도 기존 상품에 소급적용되지 않습니다.<br/>
      - 금칙어를 제거해도 기존 상품명에 다시 추가되지 않습니다.<br/>
      - 금칙어의 기존상품 일괄적용은 개발팀에 문의해주세요.<br/>
    </b-modal>

    <b-modal title="상품명 테스트" size="xl" v-model="modal.test" ok-only>
      - 한 줄에 하나의 상품명을 입력해주세요.<br/>
      <b-textarea v-model="form.test.asis" rows="8">

      </b-textarea>
      <div class="my-3 text-center">
        <b-button variant="primary" @click="applyTest">금칙어 적용 <i class="fa fa-arrow-down"></i></b-button>
      </div>
      <b-textarea v-model="form.test.tobe" rows="8">

      </b-textarea>
    </b-modal>
  </div>
</template>

<script>
import {down} from '@/shared/impexp'
import FormOptions from "../../modules/FormOptions.vue";

export default {
  name: 'ForbiddenWord',
  components: {FormOptions},
  title: '금칙어 관리',
  data() {
    return {
      form: {
        list: {
          words: '',
          replaces: '',
          goods_no: '',
          search: '',
          rule: 'ALL',
        },
        test: {
          asis: '',
          tobe: '',
        },
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      fWords: [],
      ruleReplace: [],
      busy: {list: false, listmore: false, add: false, remove: false, down: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {warn: false, test: false},
      collapse: {addRule: true},
      perPage: 20,
      oldFWords: [],

      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'word', label: '대상단어', sortable: true},
          {key: 'rule', label: '규칙', sortable: true},
          {key: 'tobe', label: '치환단어'},
          {key: '_dt', label: '등록일시', sortable: true},
        ]
      },
      formOptions: [
        [
          {
            name: '규칙', key: 'rule', options: [
              {text: '전체', value: 'ALL'},
              {text: '금칙어(remove)', value: 'remove', variant: 'danger'},
              {text: '치환규칙(replace)', value: 'replace', variant: 'warning'}
            ]
          }
        ],
      ],
    }
  },
  async created() {
    const j = await this.$api.postJson( '/meta/forbiddenWord', this.form.list);
    this.fWords = j.list.filter(e => e.rule === 'remove').map(e => e.word).sort((a, b) => b.length - a.length || a.localeCompare(b));
    this.ruleReplace = j.list.filter(e => e.rule === 'replace');
    this.list();
  },
  methods: {
    async list() {
      await this.$api.postTable(this, '/meta/forbiddenWord', this.form.list);
      // this.fWords = this.items.list.filter(e => e.rule === 'remove').map(e => e.word).sort((a, b) => b.length - a.length || a.localeCompare(b));
      // this.ruleReplace = this.items.list.filter(e => e.rule === 'replace');

      // 룰 정비용
      // let fwMap = this.$utils.arr2map(this.items.fWords);
      // this.oldFWords = this.items.fWords.filter(e => !(e.split('/').length > 1 && e.split('/').every(e => fwMap[e])));
      // console.log(this.oldFWords.set().join('\n'));
    },
    async addWords() {
      const words = this.form.list.words.trim().split(/\s*\r?\n\s*/);
      const rules = words.map(e => ({word: e, rule: 'remove'}));
      this.busy.add = true;
      const j = await this.$api.postJson('/meta/forbiddenWord/addRules', {rules});
      this.busy.add = false;
      if (j) {
        this.form.list.words = '';
        this.list();
      }
    },
    async addReplaces() {
      const replaces = this.form.list.replaces.trim().split(/\s*\r?\n\s*/);
      const errors = [];
      const rules = replaces.map(e => {
        const [word, tobe] = e.split('=>').map(e => e.trim());
        if (!word || !tobe) errors.push(e);
        return {word, tobe, rule: 'replace'};
      });
      if (errors.length) return alert(`다음 치환규칙들을 형식에 맞게 입력해주세요\n${errors.join('\n')}`);
      this.busy.add = true;
      const j = await this.$api.postJson('/meta/forbiddenWord/addRules', {rules});
      this.busy.add = false;
      if (j) {
        this.form.list.replaces = '';
        this.list();
      }
    },
    btnAction (row, event) {
      if (event === 'remove') {
        this.remove(row);
      }
    },
    async downXlsx(group) {
      if (this.items.list.length === 0) return alert('다운로드할 내용이 없습니다');

      const header = ['대상단어', '규칙', '치환단어', '등록일시'];
      const fields = ['word', 'rule', 'tobe', '_dt'];

      down(this.items.list.filter(e => !group || e.rule === group), header, fields, `금칙어_${this.$utils.dt()}`, 'xlsx');
    },
    async removeSelected() {
      const selected = this.items.list.filter(e => e.selected);
      if (selected.length === 0) return alert('삭제할 규칙을 선택해 주시기 바랍니다.');
      if (!confirm(`${selected.length} 개의 규칙을 정말로 삭제하시겠습니까?`)) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/meta/forbiddenWord/remove', {words: selected.map(e => e.word)});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },
    test() {
      this.form.test.asis = '';
      this.form.test.tobe = '';
      this.modal.test = true;
    },
    applyTest() {
      const names = this.form.test.asis.trim().split(/\s*\r?\n\s*/);

      this.form.test.tobe = names.map(e => this.applyFWords(e).name).join('\n');
    },
    applyFWords(name) {
      /*
        3안 - 특수기호가 단독으로 제거되면 브랜드명이 훼손된다.
        브랜드명을 임시 보관 후 붙이거나,
        브랜드가 사용하는 특수기호를 제외 후 교체한다.
       */
      const matchedWords = [];
      name = ' ' + name + ' '; // 금칙어 전후로 스페이스가 있어야 한다(단어로 구분이 되어야 한다)

      const fChars = this.fWords.filter(w => w.length === 1 && !w.match(/[a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]/i)).concat([' /', '/ ']);
      const fWords = this.fWords.filter(w => w.length > 1 || w.match(/[a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]/i));

      for (const r of this.ruleReplace) {
        if (name.includes(r.word)) {
          matchedWords.push(`${r.word}=>${r.tobe}`);
          name = name.replaceAll(r.word, r.tobe);
        }
      }
      for (const w of fWords) {
        const escWord = w.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape regexp
        const re = new RegExp(`\\s*[^a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]${escWord}[^a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]\\s*`, 'gi');
        const matched = name.match(re);

        if (matched) {
          matchedWords.push(w);
          matched.forEach(e => {
            name = name.replace(e, ' ');
          });
        }
      }
      for (const w of fChars) {
        if (name.includes(w)) {
          matchedWords.push(w);
          name = name.replaceAll(w, ' ');
        }
      }
      return {name: name.replace(/\s+/g, ' ').trim(), matchedWords};
    },
    async downTestXlsx() {
      const goodsNos = this.form.list.goods_no.trim().split(/\D+/g).map(e => +e).filter(e => e);
      if (!goodsNos.length) return alert('발란코드를 입력해주세요');
      this.busy.down = true;
      const j = await this.$api.postJson('/goods/cfName', {goodsNos});
      this.busy.down = false;

      const headers = '발란코드,상품명 변경전,상품명 변경후,금칙어'.split(',');
      const fields = 'goods_no,goods_nm,goods_nm_tobe,matched'.split(',');
      j.cfs.forEach(e => {
        const {name, matchedWords} = this.applyFWords(e.goods_nm);
        e.goods_nm_tobe = name;
        e.matched = matchedWords.join(', ');
      });
      // alert('done');
      down(j.cfs, headers, fields, `ForbiddenWordsGoodsNmTest`, 'csv');
    },
    copy(type) {
      const selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('복사할 규칙을 선택해주세요');

      const text = selected.map(e => type === 'word' ? e.word :
        type === 'rule' ? `${e.rule}\t${e.word}\t${e.tobe || ''}` :
          'rule,word,tobe,_dt'.split(',').map(k => e[k] || '').join('\t')).join('\n');
      const res = this.$utils.copyToClipboard(text);
      if (res) this.$alertTop(`복사되었습니다`);
    },

    applyFWordsOld(name) {
      const matchedWords = [];
      name = ' ' + name + ' '; // 금칙어 전후로 스페이스가 있어야 한다(단어로 구분이 되어야 한다)

      /*
        한 글자이고 영어숫자한글이 아니라면 기호로 가정하고 스페이스로 바꾼다.
        스페이스로 바꾸는 이유는 '세일 상품' 과 '세일러 ..' 에서 세일을 굳이 제가하기 위해 금칙어 전후에 스페이스가 있다는 가정을 하기 때문이다.
        이 경우 당일배송 vs 당일 문제처럼 부분일치에 대해서도 어느정도 해소가 된다.
      */
      const fChars = this.items.fWords.filter(w => w.length === 1 && !w.match(/[a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]/i));
      const fWords = this.items.fWords.filter(w => w.length > 1 || w.match(/[a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]/i));
      for (const w of fChars) {
        if (name.includes(w)) {
          matchedWords.push(w);
          name = name.replaceAll(w, ' ');
        }
      }
      for (const w of fWords) {
        // 1안 - 단순 replace
        // name = name.replaceAll(w, '');

        // 2안 - 스페이스 고려 replace
        const escWord = w.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape regexp
        const re = new RegExp(`\\s+${escWord}\\s+`, 'gi');
        const matched = name.match(re);
        if (matched) {
          matchedWords.push(w);
          matched.forEach(e => {
            name = name.replace(e, ' ');
          });
        }
      }
      return {name: name.replace(/\s+/g, ' ').trim(), matchedWords};
    },
  }
}
</script>

<style>


</style>
