<template>
  <div>
    <b-alert variant="danger" show>Spreadsheet 를 이용하실 땐 공용 드라이브에 생성해야 오류가 없습니다.</b-alert>
    <b-card>
      <b-input-group class="mb-1">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list()" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="No, 규칙명, 사유, 상품명 규칙, 옵션Size 규칙" v-model="form.list.search" @keypress.enter="list()" v-focus></b-form-input>
      </b-input-group>

      <form-options v-model="form.list" v-bind="{formOptions}"></form-options>

      <div class="clearfix mt-2">
        <div class="pull-left">
          <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
        </div>
        <div class="pull-right">
          <b-button class="mr-1" variant="success" @click="$utils.open('https://www.notion.so/564f9cd24172490883b04e2174e6447b')">가이드</b-button>
        </div>
      </div>
    </b-card>

    <div class="clearfix mb-1">
      <div class="pull-left">
        <b-button @click="addRuleModal()" class="mr-1" variant="success">
          새 규칙 생성
        </b-button>
        <b-button @click="removeSelected()" class="" variant="danger" :disabled="busy.remove">
          선택삭제
          <b-spinner class="mr-1" small v-if="busy.remove"></b-spinner>
        </b-button>
      </div>
      <div class="pull-right">
        <a v-if="PRIVATE" href="/#/data/store/428?mode=view" class="btn btn-info" target="_blank">
          DB 기록 보기
          <i class="fa fa-external-link"></i>
        </a>
<!--        <b-dropdown right variant="light" class="ml-1">
          <template #button-content>
            <i class="fa fa-copy"></i>
          </template>
          <b-dropdown-item @click="copy('word')">대상단어만</b-dropdown-item>
          <b-dropdown-item @click="copy('rule')">규칙,대상단어,치환단어</b-dropdown-item>
          <b-dropdown-item @click="copy('all')">전체</b-dropdown-item>
        </b-dropdown>
        <b-button class="ml-1" variant="success" @click="downXlsx()">XLSX</b-button>-->
        <!--        <b-dropdown right text="XLSX" variant="success" class="m-1">
                  <b-dropdown-item @click="downXlsx()">전체</b-dropdown-item>
                  <b-dropdown-item @click="downXlsx('remove')">금칙어만</b-dropdown-item>
                  <b-dropdown-item @click="downXlsx('replace')">치환규칙만</b-dropdown-item>
                </b-dropdown>-->
      </div>
    </div>

    <c-table id="RunCondAction" class="RunCondAction" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + (hasMore.list ? '+' : '') + ' 개'" @get-more="list(true)">
    </c-table>

    <b-modal v-if="item.filter" title="규칙 상세보기" size="xl" v-model="modal.detail" @hide="modalHide" ok-only>
      <b-form-input class="d-none" v-model="item._id"></b-form-input>
      <b-alert variant="secondary" show>
        - Live 상품(등록됨, 노출, 재고있음)을 대상으로 합니다.<br/>
        - 상품명, 옵션Size 규칙에는 정규식을 이용할 수 있습니다. 최대한 합쳐서 규칙을 작성해주세요<br/>
        - 규칙에 부분일치를 넣으려면 .* 를 앞뒤로 붙여주세요. ex) 유아.* -> 유아용 과 매칭, 영유아 와 매칭안됨<br/>
        - 카테고리는 하위 카테고리를 포함합니다.<br/>
        - 사유에는 관련내용 및 링크를 넣어주세요.<br/>
      </b-alert>

      <b-row>
        <b-col>
          <div class="bold fs-13 mb-1">규칙명</div>
          <b-form-input v-model="item.title"></b-form-input>
        </b-col>
        <b-col>
          <div class="bold fs-13 mb-1">활성화</div>
          <c-switch v-model="item.use" style="" color="success" label variant="pill" />
        </b-col>
      </b-row>

      <div class="bold fs-13 mb-1 mt-2">사유</div>
      <b-textarea v-model="item.desc" rows="4"></b-textarea>

      <hr />

      <div class="mb-1">
        정규식은 ElasticSearch 의 형식을 따릅니다.
        <a href="//www.elastic.co/guide/en/elasticsearch/reference/current/regexp-syntax.html" target="_blank">링크 <i class="fa fa-external-link"></i></a>
        를 참고해주세요
      </div>

      <b-tabs>
        <b-tab title="규칙">
          <b-row class="mt-2">
            <b-col lg="6">
              <div class="p-3 b-a-2 border-primary">
                <b-alert variant="primary" show>대상에 포함시킬 규칙을 지정해주세요</b-alert>

                <div class="bold fs-13 mb-1 mt-2">상품명 규칙(정규식)</div>
                <b-input-group>
                  <b-input v-model="item.filter.goods_nm.re" placeholder="상품명 규칙을 입력해주세요(ex: .*키즈.*)"></b-input>
                  <b-input-group-append v-if="item.filter.goods_nm.re">
                    <b-btn variant="light" @click="$utils.copyAlert(item.filter.goods_nm.re)"><i class="fa fa-copy"></i></b-btn>
                  </b-input-group-append>
                </b-input-group>

                <div class="bold fs-13 mb-1 mt-2">옵션Size 규칙(정규식)</div>
                <b-input-group>
                  <b-input v-model="item.filter.optSize.re" placeholder="옵션Size 규칙을 입력해주세요(ex: 8A)"></b-input>
                  <b-input-group-append v-if="item.filter.optSize.re">
                    <b-btn variant="light" @click="$utils.copyAlert(item.filter.optSize.re)"><i class="fa fa-copy"></i></b-btn>
                  </b-input-group-append>
                </b-input-group>

                <div class="bold fs-13 mb-1 mt-2">포함시킬 SHOP</div>
                <shop-preset ref="inShop" v-model="item.filter.shop_id.in"></shop-preset>
                <hr/>
                <div class="bold fs-13 mb-1 mt-2">포함시킬 BRAND</div>
                <brand-preset ref="inBrand" v-model="item.filter.brand_no.in"></brand-preset>
                <hr/>
                <div class="bold fs-13 mb-1 mt-2">포함시킬 CATEGORY</div>
                <category-preset ref="inCategory" v-model="item.filter.category.in"></category-preset>
              </div>
            </b-col>
            <b-col lg="6">
              <div class="p-3 b-a-2 border-warning">
                <b-alert variant="warning" show>대상에서 제외할 규칙을 지정해주세요</b-alert>

                <div class="bold fs-13 mb-1 mt-2">상품명 규칙(정규식)</div>
                <b-input-group>
                  <b-input v-model="item.filter.goods_nm.nre" placeholder="상품명 규칙을 입력해주세요(ex: .*디키즈.*)"></b-input>
                  <b-input-group-append v-if="item.filter.goods_nm.nre">
                    <b-btn variant="light" @click="$utils.copyAlert(item.filter.goods_nm.nre)"><i class="fa fa-copy"></i></b-btn>
                  </b-input-group-append>
                </b-input-group>

                <div class="bold fs-13 mb-1 mt-2">옵션Size 규칙(정규식)</div>
                <b-input-group>
                  <b-input v-model="item.filter.optSize.nre" placeholder="옵션Size 규칙을 입력해주세요(ex: [2-9]8A)"></b-input>
                  <b-input-group-append v-if="item.filter.optSize.nre">
                    <b-btn variant="light" @click="$utils.copyAlert(item.filter.optSize.nre)"><i class="fa fa-copy"></i></b-btn>
                  </b-input-group-append>
                </b-input-group>

                <div class="bold fs-13 mb-1 mt-2">제외시킬 SHOP</div>
                <shop-preset ref="ninShop" v-model="item.filter.shop_id.nin"></shop-preset>
                <hr/>
                <div class="bold fs-13 mb-1 mt-2">제외시킬 BRAND</div>
                <brand-preset ref="ninBrand" v-model="item.filter.brand_no.nin"></brand-preset>
                <hr/>
                <div class="bold fs-13 mb-1 mt-2">제외시킬 CATEGORY</div>
                <category-preset ref="ninCategory" v-model="item.filter.category.nin"></category-preset>
              </div>
            </b-col>
          </b-row>

          <hr />

          <b-btn variant="info" @click="runCustomQueryCount()">위 조건으로 Live 상품 카운팅</b-btn>
          <b-spinner v-if="busy.count" class="va-middle ml-3" small></b-spinner>
          <div v-else class="d-inline-block va-middle ml-3 fs-18">
            {{typeof filterCount === 'number' ? $utils.comma(filterCount) : '-'}}
          </div>
          <br/>
          <b-btn variant="success" class="mt-2" @click="sendCustomQuery()">위 조건으로 등록상품조회에서 검색<i class="fa fa-external-link ml-1"></i></b-btn>
        </b-tab>
        <b-tab title="Trigger">
          <b-form-checkbox v-model="item.trigger.cron">일정 시간마다</b-form-checkbox>
          <div v-if="item.trigger.cron">
            <b-form class="mt-1" inline>
              <b-form-input class="text-center w-50px" v-model.number="item.triggerCron.startHour"></b-form-input>&nbsp;시 부터&nbsp;
              <b-form-input class="text-center w-50px" v-model.number="item.triggerCron.endHour"></b-form-input>&nbsp;시 까지&nbsp;
              <b-form-select class="w-130px mx-2" v-model="item.triggerCron.hour" :options="options.hourInterval"></b-form-select>
              <b-form-checkbox-group v-model="item.triggerCron.weekday" :options="options.weekday"></b-form-checkbox-group>
            </b-form>
            <div class="mt-1">
              * 0 시부터 23 시까지가 하루종일을 의미합니다.
            </div>
          </div>
<!--          <b-form-checkbox v-model="item.trigger.onRegister">상품이 등록될 때</b-form-checkbox>-->
<!--          <b-form-checkbox v-model="item.trigger.onModify">상품이 수정될 때</b-form-checkbox>-->
        </b-tab>
        <b-tab title="Action">
          <b-form-checkbox v-model="item.action.setNotView">상품을 미노출 상태로 변경합니다.</b-form-checkbox>
          <div class="mt-1 mb-2 p-2 px-3 border-secondary b-a-1" v-if="item.action.setNotView">
            <div class="">미노출 사유</div>
            <b-form-input class="w-400px" v-model="item.actionSetNotView.reason"></b-form-input>

            <div class="mt-1">미노출 처리자</div>
            <b-form-select class="w-400px" v-model="item.actionSetNotView.notViewBy" :options="options.notViewBy"></b-form-select>
          </div>

          <b-form-checkbox v-model="item.action.sendSlack">Slack Channel 에 메시지를 보냅니다.</b-form-checkbox>
          <div class="mt-1 mb-2 p-2 px-3 border-secondary b-a-1" v-if="item.action.sendSlack">
            <div class="">Slack Channel (메시지가 안보인다면, SlackApp 을 채널에 추가해주세요)</div>
            <b-form-input class="w-400px" v-model="item.actionSendSlack.channel"></b-form-input>
          </div>

          <b-form-checkbox v-model="item.action.addToSheet">Spreadsheet 에 발란코드를 누적시킵니다.</b-form-checkbox>
          <div class="mt-1 mb-2 p-2 px-3 border-secondary b-a-1" v-if="item.action.addToSheet">
            <div class="">Spreadsheet Link <span class="text-danger">(공용 드라이브의 시트인지 확인해주세요)</span></div>
            <b-input-group class="w-500px">
              <b-form-input v-model="item.actionAddToSheet.link"></b-form-input>
              <b-input-group-append>
                <b-button v-if="item.actionAddToSheet.link && item.actionAddToSheet.link.startsWith('http')"
                          variant="success" @click="$utils.open(item.actionAddToSheet.link)">
                  <i class="fa fa-external-link"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <div class="mt-1">Spreadsheet ID</div>
            <b-form-input class="w-400px" v-model="item.actionAddToSheet.spreadsheetId" readonly></b-form-input>

            <div class="mt-1">시트명 및 범위</div>
            <b-form-input class="w-200px" v-model="item.actionAddToSheet.range"></b-form-input>
          </div>

          <b-form-checkbox v-model="item.action.addToDb">DB 에 로그를 기록합니다.</b-form-checkbox>
          <div class="mt-1 mb-2 p-2 px-3 border-secondary b-a-1" v-if="item.action.addToDb">
            <div class="">Tags</div>
            <v-select v-model="item.actionAddToDb.tags" multiple taggable push-tags placeholder="TAG 를 입력해주세요">
              <template v-slot:no-options>
                <em style="opacity: 0.5;">관련 TAG 를 입력해주세요.</em>
              </template>
            </v-select>
          </div>
        </b-tab>
        <b-tab v-if="item._id" title="Info">
          <b-row>
            <b-col cols="4">
              <small>최초 생성자</small>
              <div v-if="item_org._cuid">{{item_org._cuid}} ({{item_org._cname}})</div>
            </b-col>
            <b-col cols="4">
              <small>최초 생성일시</small>
              <div v-text="item_org._cdt"></div>
            </b-col>
            <b-col cols="4">
              <small>최근 수정일시</small>
              <div v-text="item_org._mdt"></div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="$R('DEV')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: {...item_org, ...item}})">
          JSON
        </b-button>
        <template v-if="item._id">
          <b-button variant="outline-danger" @click="removeRule()" :disabled="busy.remove">
            삭제
            <b-spinner class="mr-1" small v-if="busy.remove"></b-spinner>
          </b-button>
          <b-button variant="success" @click="ok()" :disabled="busy.save">
            저장
            <b-spinner class="mr-1" small v-if="busy.save"></b-spinner>
          </b-button>
        </template>
        <b-button v-else variant="success" @click="ok()" :disabled="busy.save">
          생성하기
          <b-spinner class="mr-1" small v-if="busy.save"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.RunCondAction .table th, .RunCondAction .table td {
  padding: 0.5rem;
  letter-spacing: -0.3px;
}
</style>
<script>
import {down} from '@/shared/impexp'
import FormOptions from "../../modules/FormOptions.vue";
import {Switch as cSwitch} from '@coreui/vue'
import Vue from "vue";

export default {
  name: 'RunCondAction',
  components: {FormOptions, cSwitch},
  title: '상품 조건부 처리',
  data() {
    return {
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      defaultForm: {
        list: {
          search: '',
          goods_nm_re: 'ALL',
          optSize_re: 'ALL',
          shop_in: 'ALL',
          brand_in: 'ALL',
          category_in: 'ALL',
          goods_nm_nre: 'ALL',
          optSize_nre: 'ALL',
          shop_nin: 'ALL',
          brand_nin: 'ALL',
          category_nin: 'ALL',
          triggerCron: 'ALL',
          actionSetNotView: 'ALL',
          actionAddToSheet: 'ALL',
          actionAddToDb: 'ALL',
        },
        item: {
          _id: '',
          title: '',
          desc: '',
          use: true,
          filter: {
            goods_nm: {re: '', nre: ''},
            optSize: {re: '', nre: ''},
            shop_id: {in: [], nin: []},
            brand_no: {in: [], nin: []},
            category: {in: [], nin: []},
          },
          trigger: {cron: false, onRegister: false, onModify: false},
          triggerCron: {cron: '', hour: 1, startHour: 8, endHour: 19, weekday: [0, 1, 2, 3, 4, 5, 6]},
          triggerOnRegister: {},
          triggerOnModify: {},
          action: {setNotView: false, sendSlack: false, addToSheet: false, addToDb: false},
          actionSetNotView: {reason: '', notViewBy: 'balaan'},
          actionSendSlack: {channel: ''},
          actionAddToSheet: {link: '', spreadsheetId: '', range: ''},
          actionAddToDb: {tags: []},
        },
      },
      form: {
        list: {},
        test: {
          asis: '',
          tobe: '',
        },
      },
      lastBody: {list: {}},
      item: {},
      item_org: {},
      items: {list: []},
      busy: {list: false, listmore: false, save: false, remove: false, count: false, down: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {detail: false, warn: false, test: false},
      perPage: 20,
      filterCount: null,

      fields: {
        list: [
          {key: 'selected', class: 'w-50px'},
          {key: 'no', label: 'No', class: 'w-50px'},
          {key: 'title', label: '규칙명'},
          {key: 'html1', label: '상품명, 옵션Size 규칙', class: 'mw-400px'},
          // {key: 'html2', label: '옵션Size 규칙'},
          {key: 'html3', label: '기타 규칙', class: 'text-center w-140px'},
          // {key: 'html4', label: 'Brand 규칙', class: 'text-center w-140px'},
          // {key: 'html5', label: 'Category 규칙', class: 'text-center w-140px'},
          {key: 'html6', label: '트리거', class: 'text-center w-120px'},
          {key: 'html7', label: '동작', class: 'text-center mw-150px'},
          {key: 'html8', label: '등록일시', class: 'text-center w-100px'},
          {key: '_actions', label: '상세', class: 'text-center w-70px', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
        ]
      },
      formOptions: [
        [
          {name: '포함할 상품명 규칙', key: 'goods_nm_re', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '포함할 옵션Size 규칙', key: 'optSize_re', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '포함할 Shop', key: 'shop_in', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '포함할 Brand', key: 'brand_in', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '포함할 Category', key: 'category_in', options: this.$F.formOptionsPreset.EXISTS_YN},
          {key: 'divider'},
          {name: '제외할 상품명 규칙', key: 'goods_nm_nre', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '제외할 옵션Size 규칙', key: 'optSize_nre', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '제외할 Shop', key: 'shop_nin', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '제외할 Brand', key: 'brand_nin', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '제외할 Category', key: 'category_nin', options: this.$F.formOptionsPreset.EXISTS_YN},
          {key: 'divider'},
          {name: '일정 시간마다', key: 'triggerCron', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '미노출', key: 'actionSetNotView', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: '스프레드시트', key: 'actionAddToSheet', options: this.$F.formOptionsPreset.EXISTS_YN},
          {name: 'DB 기록', key: 'actionAddToDb', options: this.$F.formOptionsPreset.EXISTS_YN},
        ],
      ],
      options: {
        hourInterval: [
          {text: '1시간 마다', value: 1},
          {text: '2시간 마다', value: 2},
          {text: '3시간 마다', value: 3},
          {text: '4시간 마다', value: 4},
          {text: '6시간 마다', value: 6},
          {text: '8시간 마다', value: 8},
          {text: '12시간 마다', value: 12},
          {text: '하루 1번', value: 24},
        ],
        weekday: [
          {text: '일', value: 0},
          {text: '월', value: 1},
          {text: '화', value: 2},
          {text: '수', value: 3},
          {text: '목', value: 4},
          {text: '금', value: 5},
          {text: '토', value: 6},
        ],
        notViewBy: [
          {text: 'BALAAN (파트너가 상태변경 불가)', value: 'balaan'},
          {text: 'Partner (파트너가 상태변경 가능)', value: 'partner'},
        ],
      },
      cronMap: {
        '0 * * * *': '1시간 마다',
        '0 */2 * * *': '2시간 마다',
        '0 */4 * * *': '4시간 마다',
        '0 */6 * * *': '6시간 마다',
        '0 */8 * * *': '8시간 마다',
        '0 */12 * * *': '12시간 마다',
        '0 0 * * *': '1일 마다',
      },
      triggerNameMap: {
        cron: '일정 시간마다',
        onRegister: '상품이 등록될 때',
        onModify: '상품이 수정될 때',
      },
      actionNameMap: {
        setNotView : '미노출',
        sendSlack : '슬랙전송',
        addToSheet : '스프레드시트',
        addToDb : 'DB기록'
      }
    }
  },
  async created() {
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));

    const meta = await this.$api.getMeta('shop,brand,category');
    if (!meta) return; // 미로그인 등으로 값이 없을 때

    meta.shop.forEach(s => {
      s.value = s.shop_id;
      s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    }); // use_yn 무관 일단 정보는 필요
    // this.shop 에 use_yn = y 만 남는다면 shop preset 에서 use_yn = y 를 선택했을 때 갯수가 같아지는 문제가 있다.
    this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    // this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (k === 'tabIndex') {
          this.tabIndex = +this.$route.query[k];
        }
      });
      if (this.$route.query.no) {
        const no = this.$route.query.no;
        this.form.list.search = no;
        await this.list();
        let item = this.items.list.filter(e => e.no === +no)[0];
        if (item) this.showModal({item});
        return;
      }
    }

    this.list();
  },
  watch: {
    item: {
      deep: true,
      handler(v) {
        if (v.actionAddToSheet.link) {
          const match = v.actionAddToSheet.link.match(/spreadsheets\/d\/([^?/]+)/);
          if (match) {
            v.actionAddToSheet.spreadsheetId = match[1];
          } else {
            v.actionAddToSheet.spreadsheetId = '';
          }
        }
      }
    }
  },
  methods: {
    async list() {
      await this.$api.postTable(this, '/meta/pattern/runCondAction/list', this.form.list, {more: false, fnAssign: this.assignTableData});
    },
    makeRuleBadge(obj, name, type) {
      if (type === 're') {
        return [obj.re && `<span class="badge badge-success">${name} 포함</span><span class="badge badge-light" style="white-space: normal">${obj.re}</span>`,
          obj.nre && `<span class="badge badge-warning">${name} 제외</span><span class="badge badge-light" style="white-space: normal">${obj.nre}</span>`]
          .filter(e => e).join('<br/>');
      } else if (type === 'in') {
        return [obj.in.length && `<span class="badge badge-success">${name} 포함</span><span class="badge badge-light">${obj.in.length}</span>`,
          obj.nin.length && `<span class="badge badge-warning">${name} 제외</span><span class="badge badge-light">${obj.nin.length}</span>`]
          .filter(e => e).join(' ');
      }
    },
    assignTableData(e) {
      e.html1 = [
        this.makeRuleBadge(e.filter.goods_nm, '상품명', 're'),
        this.makeRuleBadge(e.filter.optSize, '옵션Size', 're')
      ].filter(e => e).join('<br/>');
      e.html3 = [
        this.makeRuleBadge(e.filter.shop_id, 'SHOP', 'in'),
        this.makeRuleBadge(e.filter.brand_no, 'BRAND', 'in'),
        this.makeRuleBadge(e.filter.category, 'CATE', 'in')
      ].filter(e => e).join('<br/>');
      e.html6 = Object.entries(e.trigger).filter(t => t[1])
        .map(t => this.triggerNameMap[t[0]] + (t[0] === 'cron' ? '<br /><small>' + e.triggerCron.cron + '</small>' : '')).join('<br/>') || '없음';
      e.html7 = e.use ? (Object.entries(e.action).filter(e => e[1]).map(e => this.actionNameMap[e[0]]).join(', ') || '없음') : `<span class="badge badge-warning">OFF</span>`;
      e.html8 = `<span class="" title="${e._dt}">${this.$utils.reduceDT(e._dt || '')}</span>`;

      // shop_id 등의 value 를 바탕으로 객체를 설정한다
      const {shop_id, brand_no, category} = e.filter;
      if (shop_id.in.length) shop_id.in = this.shop.filter(e => e.value.in(shop_id.in));
      if (shop_id.nin.length) shop_id.nin = this.shop.filter(e => e.value.in(shop_id.nin));
      if (brand_no.in.length) brand_no.in = this.brand.filter(e => e.value.in(brand_no.in));
      if (brand_no.nin.length) brand_no.nin = this.brand.filter(e => e.value.in(brand_no.nin));
      if (category.in.length) category.in = this.category.filter(e => e.value.in(category.in));
      if (category.nin.length) category.nin = this.category.filter(e => e.value.in(category.nin));
    },
    btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    showModal(row) {
      // htmlx 등이 섞여있다. defaultForm.item 에 있는 key 만 필터링한다.
      const item = Object.entries(row.item).filter(e => this.defaultForm.item[e[0]] !== undefined).dict();
      this.item_org = row.item;
      this.item = this.$utils.clone(item);
      this.filterCount = null;
      this.modal.detail = true;
    },
    async modalHide(event) {
      const modifiedCols = Object.keys(this.item).filter(k => this.defaultForm.item[k] !== undefined)
        .filter(e => JSON.stringify(typeof this.item_org[e] === 'string' ? this.item_org[e].trim() : JSON.stringify(this.item_org[e]))
          !== JSON.stringify(typeof this.item[e] === 'string' ? this.item[e].trim() : JSON.stringify(this.item[e])));
      if (event.trigger === 'ok') {
        event.preventDefault && event.preventDefault();
        this.saveRule();
      } else if (['cancel', 'headerclose', 'esc', 'backdrop'].includes(event.trigger)) {
        if (modifiedCols.length) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
              event.preventDefault && event.preventDefault();
            }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        }
      }
    },
    async addRuleModal() {
      this.item_org = this.$utils.clone(this.defaultForm.item);
      this.item = this.$utils.clone(this.item_org);
      this.filterCount = null;
      this.modal.detail = true;
    },
    validateRule(item) {
      if (!item.title) return {ok: 0, msg: '규칙명을 입력해주세요'};
      if (!item.desc) return {ok: 0, msg: '사유를 입력해주세요'};
      if (['goods_nm', 'optSize'].every(e => !item.filter[e].re && !item.filter[e].nre) &&
        ['shop_id', 'brand_no', 'category'].every(e => item.filter[e].in.length === 0 && item.filter[e].nin.length === 0)
      ) return {ok: 0, msg: '규칙을 하나 이상 입력해주세요'};
      if (item.trigger.cron && !(item.triggerCron.startHour >= 0 && item.triggerCron.startHour <= 23)) return {ok: 0, msg: '시작시각을 0 - 23 내에서 입력해주세요'};
      if (item.trigger.cron && !(item.triggerCron.endHour >= 0 && item.triggerCron.endHour <= 23)) return {ok: 0, msg: '종료시각을 0 - 23 내에서 입력해주세요'};
      if (item.trigger.cron && item.triggerCron.startHour > item.triggerCron.endHour) return {ok: 0, msg: '시작시각을 종료시각보다 작거나 같게 입력해주세요'};
      if (item.trigger.cron && item.triggerCron.weekday.length === 0) return {ok: 0, msg: '하나 이상의 요일을 선택해주세요'};
      if (item.action.setNotView && !item.actionSetNotView.reason) return {ok: 0, msg: '미노출 사유를 입력해주세요'};
      if (item.action.sendSlack && !item.actionSendSlack.channel) return {ok: 0, msg: '메시지를 보낼 Slack Channel 을 입력해주세요'};
      if (item.action.addToSheet && !item.actionAddToSheet.link) return {ok: 0, msg: 'Spreadsheet 의 link 를 입력해주세요'};
      if (item.action.addToSheet && !item.actionAddToSheet.range) return {ok: 0, msg: 'Spreadsheet 의 시트명 및 범위를 입력해주세요'};
      if (item.action.addToDb && !item.actionAddToDb.tags.length) return {ok: 0, msg: 'DB 에 기록할 Tag 를 하나 이상 입력해주세요'};
      return {ok: 1};
    },
    adjustFilter(filter) {
      // shop_id 등의 value 만 가져온다.
      filter = this.$utils.clone(filter);
      ['shop_id', 'brand_no', 'category'].forEach(e => {
        filter[e].in = filter[e].in.set('value');
        filter[e].nin = filter[e].nin.set('value');
      });
      return filter;
    },
    async saveRule() {
      // htmlx 등이 섞여있다. defaultForm.item 에 있는 key 만 필터링한다.
      const item = Object.entries(this.item).filter(e => this.defaultForm.item[e[0]] !== undefined).dict();

      item.filter = this.adjustFilter(this.item.filter);

      const validateResult = this.validateRule(item);
      if (!validateResult.ok) {
        return this.$utils.alert(validateResult.msg);
      }
      if (['goods_nm', 'optSize'].every(e => !item.filter[e].re) && ['shop_id', 'brand_no', 'category'].every(e => item.filter[e].in.length === 0)) {
        if (!confirm(`제외 규칙만 있는 경우, 제외할 상품을 제외한 다른 모든 상품이 대상이 됩니다. 그렇게 진행하시겠습니까?`)) return;
      }

      // count 를 실행하고 10만개 이상일 경우 경고
      this.busy.save = true;
      const count = await this.runCustomQueryCount();
      this.busy.save = false;
      if (count >= 100000 && !confirm(`해당 규칙에 일치하는 상품 수가 10만개가 넘습니다. 저장하시겠습니까?`)) return;

      this.busy.save = true;
      const j = await this.$api.postJson('/meta/pattern/runCondAction/save', {item});
      this.busy.save = false;
      if (j) {
        this.list();
        this.modal.detail = false;
      }
    },
    async removeRule() {
      if (!confirm(`정말로 삭제하시겠습니까?`)) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/meta/pattern/runCondAction/remove', {_ids: [this.item._id]});
      this.busy.remove = false;
      if (j) {
        this.list();
        this.modal.detail = false;
      }
    },
    async removeSelected() {
      const selected = this.items.list.filter(e => e.selected);
      if (selected.length === 0) return alert('삭제할 규칙을 선택해 주시기 바랍니다.');
      if (!confirm(`${selected.length} 개의 규칙을 정말로 삭제하시겠습니까?`)) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/meta/pattern/runCondAction/remove', {_ids: selected.map(e => e._id)});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },

    async sendCustomQuery() {
      const filter = this.adjustFilter(this.item.filter);
      const j = await this.$api.postJson('/meta/pattern/runCondAction/getQuery', {filter});
      if (j) {
        localStorage.setItem('params#confirmed.customQuery', JSON.stringify(j.esQuery));
        this.$utils.open(`/#/goods/?runCustomQuery=true`)
        // this.$router.push(`/goods/?runCustomQuery=true`);
      }
    },
    async runCustomQueryCount() {
      const filter = this.adjustFilter(this.item.filter);
      this.busy.count = true;
      const j = await this.$api.postJson('/meta/pattern/runCondAction/getQuery', {filter});
      this.busy.count = false;
      if (j) {
        this.busy.count = true;
        const res = await this.$api.postJson('/goods/confirmed/es', {customQuery: j.esQuery, countOnly: true});
        this.busy.count = false;
        if (res) {
          this.filterCount = res.count;
          return res.count;
        }
      }
    },
  }
}
</script>

<style>


</style>
