<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="상품 조건부 처리">
        <run-cond-action></run-cond-action>
      </b-tab>
      <b-tab title="금칙어 관리" v-if="$R('FWORD_W')">
        <forbidden-word v-if="loaded[1]"></forbidden-word>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import RunCondAction from './RunCondAction.vue'
import ForbiddenWord from './ForbiddenWord.vue'

export default {
  name: 'GoodsIntegrity',
  title: '상품 건전성 관리',
  components: {RunCondAction, ForbiddenWord},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
